@import '../fonts/fonts.css';

.info-zone {
	width: 100%;
	height: 100%;

	justify-content: center;
	text-align: center;
	font-size: 20px;
	font-weight: bold;

	display: flex;
	align-items: center;

	color : black;
	background-color: white;
}

.stepper-rouds {
	position: static;
	right: 33%;
	margin-right: 15px;
	position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 20px;
  z-index: 3;
}

/* TABLET */
@media screen and (max-width: 1224px) and (min-width: 580px) {
	.stepper-rouds {
		right: 0;
	}
}

/* PHONE */
@media screen and (max-width: 580px) {
	.stepper-rouds {
		right: -5%;
	}
}

.stepper-rouds .round {
	cursor: pointer;
	margin-top: 15px;
	width: 7px;
	height: 7px;
	border: 1px solid #4A4861;
	border-radius: 50%;
}

/* PRESENTATION PART */
.presentation-container {
	font-family: Cocogoose;
	color: #4A4861;
	font-weight: 400;
}

.presentation-container .myName {
	font-family: Bebas Neue;
	font-size: 70px;
}

.presentation-container .separator {
	width: 100%;
	height: 2px;
	background-color: #7E7F98;
}

.presentation-container .infos {
	font-family: sans-serif;
	margin : 15px 0;
	display: flex;
	flex-direction: column;
	color: #7E7F98;
}

.presentation-container .infos a {
	color: #7E7F98;
	font-family: Cocogoose;
	font-weight: 400;
}

.presentation-container .avatar {
	margin: 25px auto;
	height: 300px;
	width: 300px;
	display: flex;
	align-items: center;
}

.presentation-container .image {
	margin: 15px auto;
	height: 330px;
	width: 330px;

	animation: rotate 1000ms linear infinite;
	transform: rotate(5deg);
}

@keyframes rotate {
	0% { transform: rotate(5deg) }
	50%{ transform: rotate(-5deg) }
    100%{ transform: rotate(5deg) }
}

/* COMPETENCES PART */
.competences-container {
	font-family: Cocogoose;
	color: #4A4861;
	font-weight: 400;
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: center;
	align-items: center;
}

.competences-container .myName {
	font-family: Bebas Neue;
	font-size: 70px;
	margin: 0;
}

.competences-container .infos {
	font-style: italic;
	margin: 0 0 45px 0;
	color: #7E7F98;
}

.competences-container .competences-icons-container {
	display: flex;
	width: 100%;
	justify-content: center;
}

.competences-container .competences-section-container {
	cursor: pointer;
	color: #7E7F98;

	border: 2px solid #7E7F98;
	padding: 55px 40px;
	border-radius: 15px;
	margin: 0 15px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transition: .5s;
}

.competences-container .competences-section-container:hover {
	background-color: #4A4861;
	border-color: #4A4861;
	color: white;
}

.competences-container .competences-explanation-container {
	margin-top: 20px;
	text-align: left;
	width: 80%;
	height: 100px;
}

.competences-container .competences-explaination-title {
	color: #7E7F98;
	margin-bottom: 10px;
}

/* EXPERIENCES PART */
.experiences-container {
	font-family: Cocogoose;
	font-weight: 400;
	color: #4A4861;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.experiences-container .title {
	font-family: Bebas Neue;
	font-size: 70px;
	margin-bottom: 20px
}

.experiences-container .showExp-container {
	display: flex;
	align-items: center;
	padding: 0 7%;
	min-height: 330px;
	justify-content: space-between;
}

.experiences-container .experiences-wrapper {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 0 45px;
}

.experiences-container .experience-wrapper {
	display: flex;
	flex-direction: column;
	overflow-wrap: break-word;
}

.experiences-container .experience-header {
	height: 87px;
	display: flex;
	justify-content: space-between;
}

.experiences-container .experience-title {
	display: flex;
	flex-direction: column;
	font-family: Bebas Neue;
	font-size: 40px;
	text-align: left;
	margin-top: 10px;
}

.experiences-container .experience-subtitle {
	text-align: left;
	font-size: 20px;

	margin-top: 10px;
	margin-right: 30px;
}

.experiences-container .experience-description {
	color: #7E7F98;
}

.experiences-container .experience-subtitle a {
	font-style: italic;
	color: #7E7F98;
}

.arrow-move-left {
	animation: move-left 500ms linear infinite;
	transform: translateX(-5px);
}

@keyframes move-left {
	0% { transform: translateX(-5px) }
	50%{ transform: translateX(5px) }
    100%{ transform: translateX(-5px) }
}

.arrow-move-right {
	animation: move-right 500ms linear infinite;
	transform: translateX(5px);
}

@keyframes move-right {
	0% { transform: translateX(5px) }
	50%{ transform: translateX(-5px) }
    100%{ transform: translateX(5px) }
}

/* FORMATION PART */

.formations-container {
	font-family: Cocogoose;
	font-weight: 400;
	color: #4A4861;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.formations-container .title {
	font-family: Bebas Neue;
	font-size: 70px;
	margin-bottom: 20px
}

.formations-container .formations-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	flex-wrap: wrap;
	margin: 0 45px;
}

.formations-container .formation-wrapper {
	margin-top: 15px;
}

.formations-container .formation-title {
	font-family: Bebas Neue;
	font-size: 40px;
	text-align: left;
	margin-top: 10px;
}

.formations-container .formation-subtitle {
	text-align: left;
	display: flex;
	flex-direction: column;
	font-size: 20px;
	color: #7E7F98;

	max-width: 300px;
	margin-top: 10px;
	margin-right: 30px;
}

.formations-container .formation-description {
	color: #7E7F98;
}

/* PORTFOLIO PART */

.portfolio-container {
	font-family: Cocogoose;
	font-weight: 400;
	color: #4A4861;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.portfolio-container .title {
	font-family: Bebas Neue;
	font-size: 70px;
	margin-bottom: 10px;
}

.portfolio-container .projects-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.portfolio-container .project-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 15px;
}

.portfolio-container .project {
	border: 2px solid #7E7F98;
	width: 300px;
	height: 300px;
	padding: 0;
	border-radius: 15px;
}

.portfolio-container .project-name {
	margin-top: 10px;
}

.portfolio-container .project-details {
	margin-top: 5px;
	color: #7E7F98;
	font-style: italic;
	font-size: 17px;
}

.portfolio-container .project-details-more {
	margin-top: 3px;
	color: #7E7F98;
	font-style: italic;
	font-size: 15px;
	font-family: calibri;
	font-weight: 600;
}
