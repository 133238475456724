html, body {
  background-color: #1f1c42;
  height: 100%;
  overflow: hidden;
}

.App {
  height: 100%;
  width: 100%;
  position: absolute;
  display: inline-flex;
  background-color: black;
}

.infos-container {
  background-color: white;
  height: 100vh;
  box-shadow: 0 0 70px white;
}

/* DRAWING SECTION */

.drawings-container {
  color : white;
  text-align: center;

  opacity: .3;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;

  width: 100%;
  height: 100%;
  background: center/cover no-repeat url('./img/water.png');
  background-size: 120%;
  background-position-y: 12%;
}

.drawing-section-container {
  width: 33%;
  height: 100%;
}

.art-website {
  position: absolute;
  bottom: 10px;
  width: 33%;
  text-align: center;
}

.art-website a {
  color: white;
  text-decoration: none;
  font-size: 20px;
}
