.phoneView-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: white;
	font-family: Cocogoose;
}

.phoneView-container .myName {
	font-family: Bebas Neue;
  color: #4A4861;
}

.phoneView-container a {
  color: #4A4861;
}

.phoneView-container .phoneNumber {
  font-family: sans-serif;
  font-size: 13px;
  margin: 5px 0;
  color: #7E7F98;
}

.phoneView-container .image {
	margin: 0 auto 25px auto;
	height: 261px;
	width: 220px;

	animation: rotate 1000ms linear infinite;
	transform: rotate(5deg);
}

@keyframes rotate {
	0% { transform: rotate(5deg) }
	50%{ transform: rotate(-5deg) }
    100%{ transform: rotate(5deg) }
}

.phoneView-container .description {
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  text-align: center;
}
